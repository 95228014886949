<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.lookandfeel')" class="settings">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{ $t('core.Set_platform_theming') }}</span>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow v-if="loadingData === true">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="loadingData === false" class="m-0">
            <CCol cols="12" lg="12">
              <CRow>
                <CCol xl="6" lg="6" md="6" class="p-0">
                  <CRow class="w-100 m-0">
                    <!-- <CCol md="6" lg="6" class="pt-0">
                      <CInput type="text" :label="$t('core.Name_of_your_company')" class="mb-0" v-model="companyData.company_name" required was-validated/>                        
                    </CCol> -->
                    <CCol md="6" lg="6" class="pt-0">                                              
                      <CInput type="text" :label="$t('core.Name_of_your_platform')" class="mb-0" v-model="companyData.brand_name" required was-validated/>                        
                    </CCol>
                    <CCol md="6" lg="6" class="pt-0">                                              
                      <CInput type="text" :label="$t('core.Email_signatory')" class="mb-0" v-model="companyData.email_signatory" required was-validated/>                        
                    </CCol>                    
                  </CRow>
              
                  <CRow class="w-100 m-0">
                    <CCol md="12" lg="12" class="pt-0">
                      <div v-if="!companyImageUploaded" class="mb-2">
                        <p class="mb-1">{{ $t('core.Current_company_image') }}</p>
                        <div class="current_image">
                          <img v-if="companyData.company_image" :src="companyData.company_image" class="content_picture"/>                          
                          <img v-else-if="companyData.environment_tag === 'harryhr'" src="img/brand/harry_hr_logo_23_06_2022.png" class="content_picture"/>
                          <img v-else-if="companyData.environment_tag === 'employalty'" src="img/brand/employalty_logo.png" class="content_picture"/>
                        </div>
                      </div>
                      <label>
                        <div class="d-flex align-items-center">
                          <span>{{ $t('registration.upload_company_image') }}</span>
                          <i class="fas fa-question-circle help_icon ml-1" v-c-tooltip="{content: $t('registration.company_image_help')}"/>
                        </div>
                        <div class="mt-1 meta">
                          <span>{{$t('core.For_best_results_upload')}} </span>
                        </div>
                      </label>
                      <image-uploader class="image_upload" accept="image/png" :preview="true" :className="['fileinput', { 'fileinput--loaded': companyImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setCompanyImage">
                        <label for="fileInput" slot="upload-label" class="mb-0" v-bind:class="{'mt-2': companyImageUploaded}">
                          <span class="upload-caption btn btn-primary m-0"><i class="fas fa-upload mr-1"/>{{companyImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image') }}</span>
                        </label>
                      </image-uploader>
                    </CCol>
                    <!-- <CCol md="6" lg="6" class="pt-0">
                      <div v-if="!headerImageUploaded" class="mb-2">
                        <p class="mb-1">{{ $t('core.Current_header_image') }}</p>
                        <div class="current_image">
                          <img v-if="companyData.header_image" :src="companyData.header_image" class="content_picture"/>
                          <img v-else :src="companyData.default_company_image_url" class="content_picture"/>                          
                        </div>
                      </div>
                      <label class="d-flex align-items-center">
                        <span>{{ $t('registration.upload_header_image') }}</span>
                        <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.header_image_help')}"/>
                      </label>
                      <image-uploader id="fileInputHeader" class="image_upload_header" :preview="true" :className="['fileinput', { 'fileinput--loaded': headerImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setHeaderImage">
                        <label for="fileInputHeader" slot="upload-label" class="mb-0" v-bind:class="{'mt-2': headerImageUploaded}">
                          <span class="upload-caption btn btn-primary m-0"><i class="fas fa-upload mr-1"/>{{headerImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image') }}</span>
                        </label>
                      </image-uploader>
                    </CCol> -->
                  </CRow>

                  <CRow class="w-100 m-0">
                    <CCol md="6" lg="6" class="pt-0">
                      <label class="d-flex align-items-center">
                        <span>{{ $t('registration.primary_color') }}</span>
                        <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.primary_color_help')}"/>
                      </label>
                      <div class="color_palette">
                        <div v-for="(color, index) in primaryColorPalette" v-bind:key="index" class="color" v-bind:style="{ backgroundColor: color }" v-bind:class="{'selected' : companyImageUploaded && companyData.primary_color === color}" @click="customPrimary = false; companyData.primary_color = color;"></div>
                        <div class="color_picker">
                          <CInput id="primaryPicker" type="color" class="mb-0" v-model="companyData.primary_color" @input="customPrimary = true;"/>
                          <label for="primaryPicker" class="h-100 w-100 d-flex align-items-center justify-content-center m-0" v-bind:class="{'selected' : customPrimary}" v-bind:style="[customPrimary ? {backgroundColor: companyData.primary_color } : {backgroundColor: 'transparent'}]">
                            <i class="fas fa-eye-dropper"></i>
                          </label>
                        </div>
                      </div>
                    </CCol>
                    <CCol md="6" lg="6" class="pt-0">
                      <label class="d-flex align-items-center">
                        <span>{{ $t('registration.secondary_color') }}</span>
                        <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.secondary_color_help')}"/>
                      </label>
                      <div class="color_palette">
                        <div v-for="(color, index) in secondaryColorPalette" v-bind:key="index" class="color" v-bind:style="{ backgroundColor: color }" v-bind:class="{'selected' : companyImageUploaded && companyData.secondary_color === color}" @click="customSecondary = false; companyData.secondary_color = color;"></div>
                        <div class="color_picker">
                          <CInput id="secondaryPicker" type="color" class="mb-0" v-model="companyData.secondary_color" @input="customSecondary = true;"/>
                          <label for="secondaryPicker" class="h-100 w-100 d-flex align-items-center justify-content-center m-0" v-bind:class="{'selected' : customSecondary}" v-bind:style="[customSecondary ? {backgroundColor: companyData.secondary_color } : {backgroundColor: 'transparent'}]">
                            <i class="fas fa-eye-dropper"></i>
                          </label>
                        </div>
                      </div>
                    </CCol>
                  </CRow>

                  <!-- <CRow class="w-100 m-0">
                    <CCol md="12" lg="12" class="pt-0">
                      <div v-if="!appBgImageUploaded" class="mb-2">
                        <p class="mb-1">{{ $t('core.Current_app_background_image') }}</p>
                        <div class="current_image">
                          <img v-if="companyData.app_background_image" :src="companyData.app_background_image" class="content_picture"/>
                          <img v-else :src="cdnBaseUrl + '/base/app/default-app-background.jpg'" class="content_picture"/>
                        </div>
                      </div>
                      <label class="d-flex align-items-center">
                        <span>{{ $t('core.Upload_app_background_image') }}</span>
                        <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('core.App_background_image_help')}"/>
                      </label>
                      <image-uploader id="fileInputAppBackground" class="image_upload_app_background" :preview="true" :className="['fileinput', { 'fileinput--loaded': appBgImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setAppBgImage">
                        <label for="fileInputAppBackground" slot="upload-label" class="mb-0" v-bind:class="{'mt-2': appBgImageUploaded}">
                          <span class="upload-caption btn btn-primary m-0"><i class="fas fa-upload mr-1"/>{{appBgImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image') }}</span>
                        </label>
                      </image-uploader>
                    </CCol>
                  </CRow> -->

                  <CRow class="w-100 m-0">
                    <CCol md="12" lg="12" class="pt-0">
                      <CButton color="primary" @click="updateCompanyLookAndFeel(companyData);">
                        <span><i class="fas fa-check mr-1"/>{{ $t('core.Update_theming') }}</span>
                      </CButton>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol xl="6" lg="6" md="6" class="p-0">
                  <appPreview :ref="'appPreview'" :environmentName="companyData.environment_name" :environmentTag="companyData.environment_tag" :previewData="companyData" previewMode="connect_social_wall" :previewContentData="null"/>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import ImageUploader from 'vue-image-upload-resize';
import { prominent } from 'color.js'

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import appPreview from '@/components/common/appPreview.vue';

export default {
  name: 'Theming',
  components: {
    ImageUploader,
    loadingSpinner,
    noPermission,
    appPreview
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,
      navigatedFromOnboardingChecklist: false,
      cdnBaseUrl: null,
      apiBaseUrl: null,
      clientToken: null,      
      companyIdExternal: null,
      primaryColorPalette: [],
      secondaryColorPalette: [],
      customPrimary: false,
      customSecondary: false,
      companyData: {
        environment_name: null,
        environment_tag: null,
        company_name: null,
        brand_name: null,
        email_signatory: null,
        header_image: null,
        company_image: null,
        app_background_image: null,
        primary_color: '#006859',
        secondary_color: '#B7B7B7'
      },
      companyImageUploaded: false,
      headerImageUploaded: false,
      appBgImageUploaded: false,
      newCompanyImageUploaded: false,
      newHeaderImageUploaded: false,
      newAppBgImageUploaded: false,
      colorSettings: {
        amount: 5,
        format: 'hex',
        group: 20,
        sample: 20,
      },
      loadingData: false,
      maxYiqValue: 200
    }
  },
  methods: {
    checkColor(color) {
      // Remove the # from the color
      color = color.replace("#", "");
      // Calculate RGB values
      let r = parseInt(color.substr(0, 2), 16);
      let g = parseInt(color.substr(2, 2), 16);
      let b = parseInt(color.substr(4, 2), 16);
      // Calculate YIQ value
      let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      // Return true if YIQ value is higher than maxYiqValue      
      return yiq >= this.maxYiqValue;
    },    
    getCompanyLookAndFeel() {
      // Start the loader
      this.loadingData = true;
      // Get the company details
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {
        let companyData = res.data.data;
        // Check if the company environment has been set
        if(companyData.details.environment_tag) this.companyData.environment_tag = companyData.details.environment_tag;
        // Check if the company environment has been set
        if(companyData.details.environment_name) this.companyData.environment_name = companyData.details.environment_name;               
        // Check if the company name has been set
        if(companyData.details.company_name) this.companyData.company_name = companyData.details.company_name;
        // Check if the brand name has been set
        (companyData.details.brand_name) ? this.companyData.brand_name = companyData.details.brand_name : this.companyData.brand_name = companyData.details.company_name;
        // Check if the email sender name has been set
        if(companyData.details.email_signatory) this.companyData.email_signatory = companyData.details.email_signatory;
        // Check if the company image has been set
        if(companyData.look_and_feel.company_image_id) this.companyData.company_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.company_image_id + '.png' + '/' + this.clientToken
        // Check if the company header image has been set
        if(companyData.look_and_feel.header_image_id) this.companyData.header_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.header_image_id + '.png' + '/' + this.clientToken
        // Check if the app background has been set
        if(companyData.look_and_feel.app_background_image_id) this.companyData.app_background_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.app_background_image_id + '.png' + '/' + this.clientToken;
        // Set the primary color
        (companyData.look_and_feel.primary_color) ? this.companyData.primary_color = companyData.look_and_feel.primary_color : this.companyData.primary_color = companyData.details.environment_primary_color;
        // Set the secondary color
        (companyData.look_and_feel.secondary_color) ? this.companyData.secondary_color = companyData.look_and_feel.secondary_color : this.companyData.secondary_color = companyData.details.environment_secondary_color;        
        // Set the default company image
        this.companyData.default_company_image_url = this.cdnBaseUrl + '/base/dashboard/harry_hr_logo.png';
        // Set the default social wall image
        this.companyData.default_social_wall_image_url = this.cdnBaseUrl + '/base/dashboard/default-socialwall.jpg';
        // Set the customPrimary value
        this.customPrimary = true;
        // Set the customSecondary value
        this.customSecondary = true;
        // Stop the loader
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateCompanyLookAndFeel(data) {
      let params = {};
      params.lookAndFeelData = {};
      params.lookAndFeelData.company_name = data.company_name;
      params.lookAndFeelData.brand_name = data.brand_name;
      params.lookAndFeelData.primary_color = data.primary_color;
      params.lookAndFeelData.secondary_color = data.secondary_color;
      params.lookAndFeelData.email_signatory = data.email_signatory;

      (this.newCompanyImageUploaded) ? params.lookAndFeelData.company_image = data.company_image : params.lookAndFeelData.company_image = null;
      (this.newHeaderImageUploaded) ? params.lookAndFeelData.header_image = data.header_image : params.lookAndFeelData.header_image = null;
      (this.newAppBgImageUploaded) ? params.lookAndFeelData.app_background_image = data.app_background_image : params.lookAndFeelData.app_background_image = null;

      // Validate the company
      let companyValidation = this.validateCompany(params.lookAndFeelData);
      // If the validation is successful, update the company. If not, show a message
      if(companyValidation.validated === true) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/core/company/lookandfeel', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('core.Theming_updated'), type: 'is-success', duration: 2000 });          
          // Update the dashboard title
          document.title = res.data.data.dashboard_title;
          // Update the dashboard primary color
          document.documentElement.style.setProperty('--DashboardPrimary', res.data.data.primary_color);                    
          // Update the dashboard secondary color
          document.documentElement.style.setProperty('--DashboardSecondary', res.data.data.secondary_color);
          // Update the companyImageId item in localStorage
          localStorage.setItem('companyImageId', res.data.data.company_image_id);
          // Update the headerImageId item in localStorage
          localStorage.setItem('headerImageId', res.data.data.header_image_id);
          // Reset newCompanyImageUploaded and newHeaderImageUploaded values
          this.newCompanyImageUploaded = false;
          this.newHeaderImageUploaded = false;
          this.newAppBgImageUploaded = false;
          // Emit theming updated event
          this.$bus.$emit('theming_updated');          
          // Navigate back if user navigated to page from onboarding checklist
          if(this.navigatedFromOnboardingChecklist) this.$router.back();
        })
        .catch(err => {
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        if(companyValidation.message === 'Company invalid') {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        } else if(companyValidation.message === 'Primary color too bright') {
          this.$buefy.toast.open({ message: this.$t('core.invalid_primary_color_used'), type: 'is-danger', duration: 2000 });
        } else if(companyValidation.message === 'Secondary color too bright') {
          this.$buefy.toast.open({ message: this.$t('core.invalid_secondary_color_used'), type: 'is-danger', duration: 2000 });
        }
      }
    },
    validateCompany(params) {
      let result = {};
      let company_name = params.company_name;
      let primary_color = params.primary_color;
      let secondary_color = params.secondary_color;

      // Check if the base information for the company is available
      if(company_name && primary_color && secondary_color) {        
        // Check if the primary color is too bright
        if(this.checkColor(primary_color) === true) {
          result = { validated: false, message: 'Primary color too bright' };
          return result;          
        }

        // Check if the secondary color is too bright
        if(this.checkColor(secondary_color) === true) {
          result = { validated: false, message: 'Secondary color too bright' };
          return result;          
        }        
        
        result = { validated: true, message: 'Company valid' };
        return result;
      } else {
        result = { validated: false, message: 'Company invalid' };
        return result;
      }
    },    
    setCompanyImage(output) {
      this.companyImageUploaded = true;
      this.newCompanyImageUploaded = true;
      this.companyData.company_image = output.dataUrl;

      prominent(output.dataUrl, { amount: this.colorSettings.amount, format: this.colorSettings.format, group: this.colorSettings.group, sample: this.colorSettings.sample}).then(color => {
        this.primaryColorPalette = color;

        for(var c = 0; c < this.primaryColorPalette.length; c++) {
          if(this.checkColor(this.primaryColorPalette[c]) === true) {            
            this.primaryColorPalette.splice(c, 1); 
            c--;
          }
        }

        this.companyData.primary_color = this.primaryColorPalette[0];        
        this.customPrimary = false;
      });

      prominent(output.dataUrl, { amount: this.colorSettings.amount, format: this.colorSettings.format, group: this.colorSettings.group, sample: this.colorSettings.sample}).then(color => {
        this.secondaryColorPalette = color;

        for(var c = 0; c < this.secondaryColorPalette.length; c++) {
          if(this.checkColor(this.secondaryColorPalette[c]) === true) {            
            this.secondaryColorPalette.splice(c, 1);
            c--;
          }
        }

        this.companyData.secondary_color = this.secondaryColorPalette[1];
        this.customSecondary = false;
      });

      // Reload the company image to make sure it is visible in the app preview
      // this.$refs.appPreview.reloadCompanyImage(this.companyData.company_image);
    },
    setHeaderImage(output) {
      this.headerImageUploaded = true;
      this.newHeaderImageUploaded = true;
      this.companyData.header_image = output.dataUrl;
      // Reload the header image to make sure it is visible in the app preview
      // this.$refs.appPreview.reloadHeaderImages(this.companyData.header_image);
    },
    setAppBgImage(output) {
      this.appBgImageUploaded = true;
      this.newAppBgImageUploaded = true;
      this.companyData.app_background_image = output.dataUrl;
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function(){
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');    
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');

    if(localStorage.getItem('navigatedFromOnboardingChecklist') !== null) {
      // Update the navigatedFromOnboardingChecklist value if necessary
      if(localStorage.getItem('navigatedFromOnboardingChecklist') === 'Y') this.navigatedFromOnboardingChecklist = true;
      // Wait 250ms and remove the organizationViewToActivate item from localStorage
      setTimeout(function() {
        localStorage.removeItem('navigatedFromOnboardingChecklist');
      }.bind(this), 500);
    }

    this.getPlatformPermissions();
    this.getCompanyLookAndFeel();
  }
}
</script>

<style>
  #fileInput,
  #fileInputHeader,
  #fileInputAppBackground {
    display: none;
  }
</style>